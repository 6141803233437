<template>
  <asom-modal v-model="showModal" title="Add New Link">
    <div class="space-y-4">
      <asom-alert v-if="error" variant="error" :error-message="error" />
      <asom-form-field
        label="Title"
        :state="inputStates('title')"
        error="Title is required"
      >
        <asom-input-text
          v-model="title"
          :state="inputStates('title')"
        />
      </asom-form-field>
      <asom-form-field>
        <asom-input-radio-group
          state="default"
          horizontal
          v-model="linkType"
          :options="linkTypeOptions"
        />
      </asom-form-field>
      <asom-form-field
        v-if="linkType === 'href'"
        label="Link"
        :state="inputStates('href')"
        error="Valid href is required"
      >
        <asom-input-text
          v-model="href"
          placeholder="https://"
          :state="inputStates('href')"
        />
      </asom-form-field>
      <asom-form-field
        label="Upload file"
        v-if="linkType === 'upload'"
        :state="inputStates('files')"
      >
        <asom-upload 
          v-model="files" 
          :category="attachmentCategories.OTHERS"
          :maxFiles="1"
        />
      </asom-form-field>
      <div class="flex flex-row justify-end gap-2">
        <asom-button :disable="isSubmitting" variant="secondary" text="Cancel" @click="closeModal"></asom-button>
        <asom-button :loading="isSubmitting" variant="primary" text="Submit" @click="submit"></asom-button>
      </div>
    </div>
  </asom-modal>
</template>
<script>
import get from 'lodash.get'
import { required, url, maxLength, requiredIf } from "@vuelidate/validators";
import { mapGetters } from "vuex";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import { updatePopularLinks } from "../services/smrtResource.service";
import { ATTACHMENT_CATEGORIES } from "@/constants/APIEnums/attachmentEnums";

export default {
  mixins: [inputStates],
  emit: [ 'update:modelValue', 'success' ],
  props: {
    modelValue: {
      type: Boolean
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      title: "",
      linkType: "href",
      href: "",
      files: [],
      isSubmitting: false,
      error: null,
    }
  },
  validations() {
    return {
      title: { required },
      href: {
        required: requiredIf(() => this.linkType === 'href'),
        url 
      },
      files: {
        required: requiredIf(() => this.linkType === 'upload'),
        maxLength: maxLength(1) 
      }
    };
  },
  watch: {
    modelValue: function(newVal) {
      if (newVal) {
        this.resetForm();
      }
    }
  },
  computed: {
    ...mapGetters({
      'links': 'smrtResource/popularLinks',
    }),
    linkTypeOptions() {
      return [
        { value: 'href', label: 'URL', description: 'External URL' },
        { value: "upload", label: 'Upload File', description: 'Upload file' },
      ];
    },
    showModal: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    },
    submissionLink() {
      if (this.linkType === 'href')
        return {
          title: this.title,
          href: this.href,
        }
      else
        return {
          title: this.title,
          fileId: get(this.files, '0.fileId', null),
          fileName: get(this.files, '0.fileName', null)
        }
    },
    attachmentCategories() {
      return ATTACHMENT_CATEGORIES;
    },
  },
  methods: {
    resetForm() {
      this.title = "";
      this.href = "";
      this.files = [];
      this.linkType = "href";
      this.v$.$reset();
    },
    closeModal() {
      this.resetForm();
      this.$emit('update:modelValue', false);
    },
    async submit() {
      this.error = null;
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        const resp = await updatePopularLinks([
          ...this.links,
          this.submissionLink,
        ]);
        if (resp.success) {
          this.$emit('success');
          this.closeModal();
        } else {
          this.error = resp.payload;
        }
        this.isSubmitting = false;
      } else {
        this.error = "Title and URL are required.";
      }
    }
  }
}
</script>