<template>
  <asom-card>
    <div class="space-y-4">
      <asom-alert variant="info" title="More links to be included"></asom-alert>
      <div class="flex flex-row justify-end" v-if="canEdit">
        <asom-button text="Add Links" icon="math-plus" @click="toggleAddLinkModal" />
      </div>
      <asom-client-table
        :columns="tableColumns"
        :data="links"
        :thClasses="linkTHeadClasses"
        :pagination="false"
      >
        <template v-slot:no="slotScoped">
          {{slotScoped.rowIndex + 1}}
        </template>
        <template v-slot:title="slotScoped">
          <asom-button
            variant="link"
            :text="slotScoped.data"
            @click="onLinkClick(slotScoped.rowData)"
          />
        </template>
        <template v-slot:action="scopedSlots">
          <div class="flex flex-col gap-1 w-48">
            <asom-button
              outline
              variant="error"
              text="Remove"
              @click="confirmDeletion(scopedSlots.rowIndex)"
            />
          </div>
        </template>
      </asom-client-table>
    </div>
  </asom-card>
  <add-popular-link v-model="showAddLinkModal" @success="loadData" />
  <asom-modal
    title="Confirm Removing Link"
    v-model="showDeleteModal"
    :dismissible="false"
  >
    <div class="space-y-4">
      <div>
        <p>Are you sure to remove this link?</p>
      </div>
      <div class="sm:flex sm:flex-row-reverse gap-2">
        <asom-button
          variant="error"
          text="Confirm"
          :disabled="isUpdating"
          :loading="isUpdating"
          @click="removeLink"
        />
        <asom-button
          outline
          variant="secondary"
          text="Cancel"
          :disabled="isUpdating"
          :loading="isUpdating"
          @click="closeDeleteModal"
        />
      </div>
    </div>
      
    </asom-modal>
</template>
<script>
import { sanitizeUrl } from '@braintree/sanitize-url'
import { mapGetters } from 'vuex'
import { downloadHandler } from '../services/serviceHandlers';
import { updatePopularLinks } from "../services/smrtResource.service";
import AddPopularLink from './_AddPopularLink.vue'

export default {
  components: {
    AddPopularLink,
  },
  data() {
    return {
      showAddLinkModal: false,
      deleteLinkIndex: -1,
      showDeleteModal: false,
      isUpdating: false,
      updatingError: null,
    }
  },
  computed: {
    ...mapGetters({
      'links': 'smrtResource/popularLinks',
      'canEdit': 'auth/isSysAdmin'
    }),
    linkTHeadClasses() {
      return {
        'no': 'w-10',
        'title': 'w-full'
      }
    },
    tableColumns() {
      return this.canEdit ? [ 'no', 'title', 'action' ] : [ 'no', 'title' ]
    }
  },
  methods: {
    sanitizeUrl,
    toggleAddLinkModal() {
      this.showAddLinkModal = true;
    },
    loadData() {
      this.$store.dispatch('smrtResource/getPopularLinks');
    },
    confirmDeletion(index) {
      this.deleteLinkIndex = index;
      this.showDeleteModal = true;
    },
    closeDeleteModal() {
      this.deleteLinkIndex = -1;
      this.showDeleteModal = false;
    },
    async removeLink() {
      this.updatingError = null;
      this.isUpdating = true;
      const _newLinks = this.links.filter((_, index) => index !== this.deleteLinkIndex);
      const resp = await updatePopularLinks(_newLinks);
      if (resp.success) {
        this.loadData();
      } else {
        this.updatingError = resp.payload;
      }
      this.isUpdating = false;
      this.closeDeleteModal();
    },
    onLinkClick({ href, fileId, fileName }) {
      if (href) {
        window.open(sanitizeUrl(href));
      }
      if (fileId && fileName) {
        downloadHandler(fileId, fileName);
      }
    }
  }
}
</script>